import Grid from "@mui/material/Grid";
import { Select } from "components/Form/SelectField";
import { useNextTranslation } from "hooks/useTranslation";

// import { useCallback } from "react";
import {
    // useProductFilteredListFilter,
    // useProductFilteredListOnChangeSelect,
    useProductFilteredListOnChangeSort,
    useProductFilteredListSort,
} from "./hooks";

const Sort = () => {
    const sort = useProductFilteredListSort();
    const onChangeSort = useProductFilteredListOnChangeSort();
    const sortKeys = Object.keys(sort ?? {}).map((key) => `${key}:${sort?.[key]}`);
    const value = sortKeys.length ? sortKeys[0] : null;
    const [t] = useNextTranslation("product");
    const recommendedLabel = t("label.recommended");
    const guidePriceAscLabel = t("label.guidePriceAsc");
    const guidePriceDescLabel = t("label.guidePriceDesc");
    const sortLabel = t("label.sort");

    const SORT_OPTIONS = [
        { label: recommendedLabel, value: "qualityMatchScore:desc" },
        { label: guidePriceAscLabel, value: "guidePrice:asc" },
        { label: guidePriceDescLabel, value: "guidePrice:desc" },
    ];
    return (
        <Select
            variant="outlined"
            options={SORT_OPTIONS}
            placeholder={sortLabel}
            label={sortLabel}
            value={value}
            onChange={onChangeSort}
        />
    );
};

// const Filter = () => {
//     const onChangeSelect = useProductFilteredListOnChangeSelect();
//     const filter = useProductFilteredListFilter();
//     const [t] = useNextTranslation("product");
//     const noneLabel = t("label.none");
//     const recentlyAddedProductLabel = t("label.recentlyAddedProduct");
//     const privateTourLabel = t("label.privateTour");
//     const filterLabel = t("label.filter");

//     const FILTER_OPTIONS = [
//         { label: noneLabel, value: "null" },
//         { label: recentlyAddedProductLabel, value: "isNew" },
//         { label: privateTourLabel, value: "isPrivate" },
//     ];

//     let filterKeys = filter ? Object.keys(filter) : [];
//     filterKeys = filterKeys.filter((filterKey) => FILTER_OPTIONS.map((option) => option.value).includes(filterKey));
//     const value = filterKeys.length ? filterKeys[0] : "null";

//     const onChange = useCallback(
//         (_newValue: string) => {
//             const newValue = _newValue === "null" ? null : _newValue;
//             onChangeSelect(newValue, value);
//         },
//         [onChangeSelect, value]
//     );

//     return (
//         <Select
//             variant="outlined"
//             options={FILTER_OPTIONS}
//             placeholder={filterLabel}
//             label={filterLabel}
//             value={value}
//             onChange={onChange}
//         />
//     );
// };

const ProductFilteredListFilters = () => {
    return (
        <Grid container spacing={3} justifyContent="flex-start" alignItems="center">
            <Grid item md={6} xs={6}>
                <Sort />
            </Grid>
            <Grid item md={6} xs={6}>
                {/* <Filter /> */}
            </Grid>
        </Grid>
    );
};

export default ProductFilteredListFilters;
