export function objectToQueryParams(params: Record<string, unknown>) {
    const urlParams = new URLSearchParams();
    for (const [name, value] of Object.entries(params)) {
        if (value !== undefined) {
            urlParams.append(name, value + "");
        }
    }

    return urlParams;
}
