import Search from "containers/Search";
import { useNextTranslation } from "hooks/useTranslation";

import {
    useProductFilteredListTreeOnChangeSearch,
    useProductFilteredListTreeOnClearSearch,
    useProductFilteredListSearchTerm,
} from "./hooks";

const ProductFilteredListSearch = () => {
    const onChange = useProductFilteredListTreeOnChangeSearch();
    const onClear = useProductFilteredListTreeOnClearSearch();
    const searchTerm = useProductFilteredListSearchTerm();
    const [t] = useNextTranslation("search");
    const refineSearchLabel = t("label.refineSearch");
    const refineSearchWithinLabel = t("label.refineSearchWithin");

    const label = searchTerm ? `${refineSearchWithinLabel} '${searchTerm}'` : refineSearchLabel;
    return <Search label={label} onSubmit={onChange} onClear={onClear} showResults={false} showPlaceholder={false} />;
};

export default ProductFilteredListSearch;
