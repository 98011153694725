import Box from "@mui/material/Box";
import NextImage, { ImageProps as NextImageProps } from "next/legacy/image";
import { useState } from "react";
import { vaultUrlLoader } from "utils/vaultUrlLoader";

import { makeStyles } from "@holibob-packages/ui-core/style";
import { base64encode } from "@holibob-packages/ui-core/utils";

// mui breakpoints + large sizes
export const breakpoints = [600, 960, 1280, 1920, 2048, 2560, 3440, 3840, 4096];

const useStyles = makeStyles()({
    imageContainer: {
        position: "relative",
        width: "100%",
    },
    image: {
        objectFit: "cover",
    },
});

export const buildLoader = ({
    folder,
    bucket,
    height: defaultHeight,
    fit = "cover",
}: {
    folder: string;
    bucket?: string;
    height?: number;
    fit?: string;
}) => {
    return ({ src, width, height }: { src: string; width?: number; height?: number }) => {
        const key = `${folder}/${src}`;

        const imageObject: Record<string, unknown> = {
            bucket,
            key,
        };

        if (width) {
            imageObject.edits = {
                resize: {
                    fit,
                    width,
                    height: height ?? defaultHeight,
                },
            };
        }

        const base64data = base64encode(JSON.stringify(imageObject));
        const url = `https://images.holibob.tech/${base64data}`;

        return url;
    };
};

type ImageProps = {
    imageClassName?: string;
} & NextImageProps;

const Image = ({ className, imageClassName, layout = "fill", height, width, ...props }: ImageProps) => {
    const { classes, cx } = useStyles();
    const [hasError, setHasError] = useState(false);
    if (hasError) return null;

    let layoutProps = {};
    if (height && width) layoutProps = { height, width };
    else layoutProps = { layout };
    return (
        <Box className={cx(classes.imageContainer, className)}>
            <NextImage
                className={cx(classes.image, imageClassName)}
                onError={() => setHasError(true)}
                {...layoutProps}
                alt="holibob"
                {...props}
            />
        </Box>
    );
};

const vaultImageLoader = buildLoader({ bucket: "tech.holibob.vault", folder: "vaultFiles" });
export const VaultImage = ({ vaultId, ...props }: $TSFixMe) => {
    if (!vaultId) return null;

    return <Image alt="vaultImage" src={vaultId} loader={vaultImageLoader} {...props} />;
};

const productImageLoader = buildLoader({ folder: "productImages" });
export const ProductImage = ({ productImageId, ...props }: Omit<ImageProps, "src"> & { productImageId?: string }) => {
    if (!productImageId) return null;

    return <Image alt="vaultImage" src={productImageId} loader={productImageLoader} {...props} />;
};

export const ImageFromVaultUrl = (props: Omit<NextImageProps, "loader">) => {
    if (!props.src) return null;

    return <Image alt="imageFromVaultUrl" {...props} loader={vaultUrlLoader} />;
};

export default Image;
