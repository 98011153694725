import Card, { CardProps } from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { styled } from "@mui/material/styles";
import Text, { TextSubtitle2 } from "components/Text";

export const ErrorCardContainer = styled(Card)({
    backgroundColor: "white",
    color: "#999",
});

export const ErrorMessage = styled(Text)({
    fontSize: "0.9em",
});

export const ErrorMessageTitle = styled(TextSubtitle2)({
    fontSize: "0.8em",
    textTransform: "uppercase",
    marginBottom: 15,
});

export const ErrorLabel = styled(Text)({
    marginBottom: "1em",
});

export type ErrorCardProps = CardProps;

export function ErrorCard(props: ErrorCardProps) {
    const { children, ...cardProps } = props;

    return (
        <ErrorCardContainer {...cardProps}>
            <CardContent>{children}</CardContent>
        </ErrorCardContainer>
    );
}

export function ErrorAlert(props: { title?: string; messages?: string[] }) {
    const { title, messages } = props;

    if (!messages?.length) return null;

    return (
        <ErrorCard>
            {title?.length && <ErrorMessageTitle gutterBottom>{title}</ErrorMessageTitle>}
            {messages.map((message: string, index: number) => (
                <ErrorMessage gutterBottom key={index}>
                    {message}
                </ErrorMessage>
            ))}
        </ErrorCard>
    );
}
