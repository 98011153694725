import { styled } from "@mui/material/styles";

import { Typography, TypographyProps } from "@holibob-packages/ui-core/components";

type FilterTreeTitleProps = {
    depth: number;
    isSelected?: boolean;
    marginSize?: "small" | "medium";
} & TypographyProps;

export const FilterTreeTitle = ({ depth, isSelected, marginSize = "medium", ...props }: FilterTreeTitleProps) => {
    return (
        <TypographyStyled data-depth={depth} data-margin-size={marginSize} data-is-selected={isSelected} {...props} />
    );
};

const TypographyStyled = styled(Typography)(({ theme }) => ({
    '&[data-margin-size="small"]': {
        marginBlockStart: theme.spacing(0.125),
    },
    '&[data-margin-size="medium"]': {
        marginBlockStart: theme.spacing(0.5),
        marginBlockEnd: theme.spacing(0.5),
    },
    '&[data-is-selected="true"]': {
        fontWeight: 700,
    },
    color: theme.palette.grey[800],
}));
