import Modal, { ModalContent } from "components/Modal";
import { TextBody, TextCaption, TextPrice } from "components/Text";
import get from "lodash/get";
import { Suspense, useState, lazy } from "react";

import { makeStyles } from "@holibob-packages/ui-core/style";

const JSONTree = lazy(() => import("react-json-tree"));

const useStyles = makeStyles()({
    linkLabel: {
        "&:hover": {
            textDecoration: "underline",
            cursor: "pointer",
        },
    },
    modalContent: {
        width: "70%",
        height: "70%",
        overflow: "scroll",
    },
});

const PricingTypeLabelText = ({ price, field = "grossFormattedText", className, variant = "body1" }: $TSFixMe) => {
    const label = get(price, field);

    return (
        <TextPrice variant={variant} className={className}>
            {label}
        </TextPrice>
    );
};

const PricingTypeLabelWithPricingDataModal = (props: $TSFixMe) => {
    const { price, className } = props;
    const { pricingData } = price;
    const { classes, cx } = useStyles();
    const [open, setOpen] = useState(false);

    return (
        <div>
            <div
                onClick={(event) => {
                    event.stopPropagation();
                    setOpen(true);
                }}
            >
                <PricingTypeLabelText {...props} className={cx(className, classes.linkLabel)} />
            </div>
            <Modal
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
            >
                <ModalContent className={classes.modalContent} onClick={(event: $TSFixMe) => event.stopPropagation()}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <TextBody>Pricing Data:</TextBody>
                        <TextCaption>
                            <JSONTree data={pricingData} theme={"colors"} />
                        </TextCaption>
                    </Suspense>
                </ModalContent>
            </Modal>
        </div>
    );
};

const PriceTypeLabel = (props: $TSFixMe) => {
    const { pricingData } = props.price;
    if (!pricingData) return <PricingTypeLabelText {...props} />;
    return <PricingTypeLabelWithPricingDataModal {...props} />;
};

export default PriceTypeLabel;
