import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import { ButtonPrimary } from "components/Button";
import MobileModal from "components/MobileModal";
import { useNextTranslation } from "hooks/useTranslation";
import { useState } from "react";

import { Spacer } from "@holibob-packages/ui-core/components";
import { makeStyles } from "@holibob-packages/ui-core/style";

const useStyles = makeStyles()({
    layoutHeader: {
        paddingBottom: 25,
    },
    layoutSidebar: {
        width: 100,
    },
});

const ProductListLayout = ({ sidebar, content, footer, header }: $TSFixMe) => {
    const { classes } = useStyles();
    const [open, setOpen] = useState(false);
    const [t] = useNextTranslation("product");
    const openFiltersLabel = t("label.openFilters");
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className={classes.layoutHeader}>{header}</div>
            <Grid container spacing={3}>
                <Hidden mdDown>
                    {sidebar && (
                        <Grid item md={3} xs={12} className={classes.layoutSidebar}>
                            {sidebar}
                        </Grid>
                    )}
                </Hidden>
                <Grid item md={sidebar ? 9 : 12} xs={12}>
                    {sidebar && (
                        <Hidden mdUp>
                            <ButtonPrimary onClick={handleOpen}>{openFiltersLabel}</ButtonPrimary>
                            <Spacer md />
                            <MobileModal handleOpen={handleOpen} handleClose={handleClose} open={open}>
                                {sidebar}
                            </MobileModal>
                        </Hidden>
                    )}
                    <div>{content}</div>
                    <Hidden mdDown>
                        <Spacer md />
                        <div>{footer}</div>
                    </Hidden>
                </Grid>
                <Hidden mdUp>
                    <Grid item xs={12}>
                        {footer}
                    </Grid>
                </Hidden>
            </Grid>
        </>
    );
};

export default ProductListLayout;
