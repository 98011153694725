import { gql } from "@apollo/client";
import useQuery from "hooks/useQuery";
import { useEffect, useState } from "react";

const QUERY = gql`
    query holibob($filter: ProductListFilter, $sort: ProductListSort, $page: Int, $pageSize: Int) {
        productList(filter: $filter, sort: $sort, page: $page, pageSize: $pageSize) {
            total
            pages
            nextPage
            prevPage
            products: nodes {
                id
                code
                slug
                name
                isFeatured
                isFavourite
                promotionType
                description
                guidePriceType
                isSupplierActivated
                isPartnerActivated
                holibobGuidePrice {
                    pricingData
                    gross
                    currency
                }
                type
                availabilityCount
                previewImage {
                    id
                }
                metaList {
                    nodes {
                        type
                        value
                    }
                }
                cancellationPolicy {
                    hasFreeCancellation
                }
            }
        }
    }
`;

export const useProductList = ({ variables, options, query = QUERY }: $TSFixMe) => {
    const [fetchPolicy, setFetchPolicy] = useState("cache-first");
    const response = useQuery(query, {
        variables,
        errorPolicy: "all",
        fetchPolicy,
        ...options,
    });

    const { error } = response;
    const data = response.data ?? response.previousData ?? {};

    useEffect(() => {
        if (error) {
            setFetchPolicy("no-cache"); // if we encounter an error then turn caching off. This is because apollo caches the data but does not cache the errors when using errorPolicy: "all".
            // must use errorPolicy: "all" in order to return errors and data at the same time.
            // this solution is not ideal since it causes the query to be refetched when policy is changed. And all other versions of the query (e.g with different filters) will also not be cached
        }
    }, [error]);

    const productErrors = {};
    let hasRootErrors = false;

    if (error) {
        const { graphQLErrors } = error;
        for (const graphQLError of graphQLErrors) {
            const { path } = graphQLError;
            const pathName = path!.join(".");

            if (pathName.includes("productList.products")) {
                const index = path![2];
                // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                productErrors[index] = graphQLError;
            } else hasRootErrors = true;
        }
    }

    const { productList } = data;
    const productListProducts = productList?.products || [];
    const { pageSize = 0, page = 0 } = variables;

    if (!productList) {
        return {
            ...response,
            hasRootErrors,
            products: [],
        };
    }

    const products = productListProducts.map((product: $TSFixMe, index: $TSFixMe) => {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        const error = productErrors[index];
        return {
            ...product,
            error,
        };
    });

    const { nextPage, prevPage } = productList;
    const { total, maxPrice, attributeTree, categoryTree, cityTree } = productList;

    return {
        ...response,
        ...productList,
        products,
        nextPage: nextPage === null ? undefined : nextPage - 1,
        prevPage: prevPage === null ? undefined : prevPage - 1,
        noResults: products.length === 0,
        hasRootErrors,
        pagination: {
            total,
            page,
            pageSize,
        },
        maxPrice,
        attributeTree,
        categoryTree,
        cityTree,
    };
};

export default useProductList;
