import { ErrorAlert } from "components/ErrorMessage";
import { useNextTranslation } from "hooks/useTranslation";
import { useViewerIsHolibobAdmin } from "hooks/useViewer";
import upperFirst from "lodash/upperFirst";

const isDev = process.env.NODE_ENV === "development";
const REQUEST_ID_KEY = "requestId:";

type ApiErrorAlertError = {
    message: string;
};
type ApiErrorAlertProps = {
    error?: ApiErrorAlertError;
    title?: string;
};

export function ApiErrorAlert(props: ApiErrorAlertProps) {
    const { error } = props;
    const [t] = useNextTranslation("home");

    const title = props.title ? props.title : t("error.general");
    const messages = useExtractErrorMessages(error);

    return <ErrorAlert title={title} messages={messages} />;
}

function useExtractErrorMessages(error?: ApiErrorAlertError) {
    const isHolibobAdmin = useViewerIsHolibobAdmin();
    if (!error?.message) {
        return undefined;
    }

    const showDebugMessages = isHolibobAdmin || isDev;

    const message = error.message.replace("GraphQL error: ", "");

    const messages = message.indexOf("\n") !== -1 ? message.split("\n") : [message];

    if (showDebugMessages) {
        return messages.map((message) => upperFirst(message.trim()));
    }

    let requestId;

    for (const msg of messages) {
        if (msg.includes(REQUEST_ID_KEY)) {
            requestId = msg.split(REQUEST_ID_KEY).pop()!.trim();
            break;
        }
    }

    return [`Request ID: ${requestId}`];
}
export default ApiErrorAlert;
