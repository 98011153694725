import FileCopyIcon from "@mui/icons-material/FileCopy";
import Tooltip from "@mui/material/Tooltip";
import { useNextTranslation } from "hooks/useTranslation";

import { makeStyles } from "@holibob-packages/ui-core/style";

const useStyles = makeStyles()((theme) => ({
    copyIcon: {
        paddingRight: 5,
        paddingLeft: 5,
        fill: "grey",
        height: 12,
        width: 12,
        cursor: "pointer",
        opacity: 0.5,
        "&:hover": {
            opacity: 0.9,
        },
        "&:active": {
            fill: theme.palette.primary.main,
        },
    },
}));

type CopyProps = {
    value: string;
    label?: string;
};
const Copy = ({ value, label }: CopyProps) => {
    const { classes } = useStyles();
    const [t] = useNextTranslation("general");
    const copyLabel = t("copy.label");

    return (
        <Tooltip title={`${copyLabel}: ${label ?? value}`} placement="top">
            <FileCopyIcon
                className={classes.copyIcon}
                onClick={(event) => {
                    event.preventDefault();
                    void navigator.clipboard.writeText(value);
                }}
            />
        </Tooltip>
    );
};

export default Copy;
