import { gql, useQuery } from "@apollo/client";

import useProductList from "./useProductList";

const QUERY = gql`
    query useProductFilteredList($filter: ProductListFilter, $sort: ProductListSort, $page: Int, $pageSize: Int) {
        productList(filter: $filter, sort: $sort, page: $page, pageSize: $pageSize) {
            total
            pages
            nextPage
            prevPage
            products: nodes {
                id
                name
                slug
                description
                isFeatured
                promotionType
                guidePriceType
                reviewRating
                reviewCount
                isFavourite
                previewImage {
                    id
                }
                holibobGuidePrice {
                    pricingData
                    gross
                    currency
                }
                metaList {
                    nodes {
                        type
                        value
                    }
                }
                banner {
                    text
                    backgroundColor
                }
                cancellationPolicy {
                    hasFreeCancellation
                }
            }
        }
    }
`;

const buildTreeQuery = (treeIds: $TSFixMe) => {
    return gql`
        query useProductFilteredListTrees($filter: ProductListFilter, $sort: ProductListSort, $page: Int, $pageSize: Int) {
            productList(filter: $filter, sort: $sort, page: $page, pageSize: $pageSize) {
                ${treeIds.includes("ATTRIBUTE") ? "attributeTree" : ""}
                ${treeIds.includes("CATEGORY") ? "categoryTree" : ""}
                ${treeIds.includes("CITY") ? "cityTree" : ""}
                ${treeIds.includes("PROVIDER") ? `providerTree { nodes { count id isSelected label type }}` : ""}
                ${
                    treeIds.includes("PRICE")
                        ? "priceTree { minPrice markList { value } maxPrice histogram currency}"
                        : ""
                }
            }
        }
    `;
};

export const useProductFilteredList = ({ variables, options }: $TSFixMe) => {
    return useProductList({ variables, options, query: QUERY });
};

export const useProductListTrees = ({ variables, options, treeIds }: $TSFixMe) => {
    const query = buildTreeQuery(treeIds);
    const response = useQuery(query, {
        variables,
        ...options,
        fetchPolicy: "no-cache",
    });

    const { data, previousData } = response;
    const currentData = data || previousData || {};
    const { productList = {} } = currentData;

    return { response, ...productList };
};

export default useProductFilteredList;
