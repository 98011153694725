import CloseIcon from "@mui/icons-material/Close";
import Modal, { ModalContent } from "components/Modal";

import { makeStyles } from "@holibob-packages/ui-core/style";

const useStyles = makeStyles()({
    modalContainer: {
        overflow: "auto",
        maxHeight: "100%",
    },
    closeIcon: {
        backgroundColor: "white",
        position: "absolute",
        right: "30px",
        top: "30px",
    },
    closeIconWrapper: {
        padding: "30px",
    },
});

export const MobileModal = ({ handleOpen, handleClose, open, children, ...props }: $TSFixMe) => {
    const { classes } = useStyles();
    return (
        <Modal open={open} onClose={handleOpen} {...props}>
            <ModalContent className={classes.modalContainer}>
                <div>
                    <div className={classes.closeIconWrapper}>
                        <CloseIcon
                            aria-label="close"
                            color="primary"
                            onClick={handleClose}
                            className={classes.closeIcon}
                        />
                    </div>
                    {children}
                </div>
            </ModalContent>
        </Modal>
    );
};

export default MobileModal;
