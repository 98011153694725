import { FormHelperText } from "@mui/material";
import FormControl, { FormControlProps } from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import MUISelect, { SelectProps as MUISelectProps } from "@mui/material/Select";
import { useRef } from "react";

import fieldDecorator from "./fieldDecorator";

type SelectProps = {
    name?: string;
    label?: string;
    options?: Array<{ value: string; label?: string }>;
    defaultValue?: string;
    value?: string | null;
    variant?: FormControlProps["variant"];
    required?: boolean;
    helperText?: string;
    onChange: (value: string) => void;
} & Omit<MUISelectProps, "onChange">;

export const Select = (props: SelectProps) => {
    const {
        name = "select",
        label,
        options = [],
        defaultValue,
        value,
        onChange: onChangeProp,
        variant = "outlined",
        required,
        helperText,
    } = props;

    const inputLabel = useRef(null);
    const id = name;
    const labelId = `${id}-label`;

    return (
        <FormControl variant={variant} fullWidth>
            {label?.length && (
                <InputLabel ref={inputLabel} id={labelId} required={required}>
                    {label}
                </InputLabel>
            )}
            <MUISelect
                fullWidth
                id={name}
                defaultValue={defaultValue}
                value={value}
                onChange={(event) => {
                    onChangeProp(event.target.value as unknown as string);
                }}
                labelId={labelId}
                input={<OutlinedInput label={label} />}
            >
                {options.map((option) => {
                    let value;
                    let label;

                    if (typeof option === "string") {
                        value = option;
                        label = option;
                    } else {
                        value = option.value;
                        label = option.label ? option.label : value;
                    }

                    return (
                        <MenuItem data-testid="selectOption" key={value} value={value}>
                            {label}
                        </MenuItem>
                    );
                })}
            </MUISelect>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};

export const SelectField = fieldDecorator<string | undefined | null>(({ input, meta: _meta, ...props }) => {
    const { name, value, onBlur, onChange, onFocus } = input;

    return <Select value={value} onBlur={onBlur} onChange={onChange} onFocus={onFocus} name={name} {...props} />;
});

export default SelectField;
