import { styled } from "@mui/material/styles";
import Button from "components/Button";
import { PriceTree } from "components/PriceSlider";
import { useNextTranslation } from "hooks/useTranslation";

import { Flex } from "@holibob-packages/ui-core/components";

import { Tree } from "../hooks";
import { FilterBranch } from "./ProductFilteredListFilterBranch";
import { FilterLeaf } from "./ProductFilteredListFilterLeaf";
import { FilterTreeTitle } from "./ProductFilteredListFilterTreeTitle";

type FilterRootProps = {
    depth: number;
    label: string;
    branches?: (Tree | PriceTree)[];
    onSelect?: (id: string) => void;
    onClear?: () => void;
    canReset: boolean;
};
export const FilterRoot = ({ depth, label, branches = [], onSelect, onClear, canReset }: FilterRootProps) => {
    const [t] = useNextTranslation("search");
    const resetLabel = t("label.reset");

    return (
        <div>
            <Flex spaceBetween>
                <FilterTreeTitle depth={depth} variant="title" size="large">
                    {label}
                </FilterTreeTitle>
                <ResetButton
                    onClick={onClear}
                    variant="text"
                    color="secondary"
                    disabled={!canReset}
                    data-is-hidden={!canReset}
                >
                    {resetLabel}
                </ResetButton>
            </Flex>
            {branches.map((branch) => (
                <FilterTree depth={depth + 1} onSelect={onSelect} {...(branch as Tree)} key={branch.label} />
            ))}
        </div>
    );
};

export const FilterTree = (tree: Tree & { depth?: number; onClear?: () => void }) => {
    const { depth = 0, type, branches: _branches = [] } = tree;
    const branches = ("nodes" in _branches ? _branches.nodes : _branches).filter(Boolean);

    let content = null;
    if (depth === 0) {
        content = <FilterRoot {...tree} depth={depth} branches={branches} />;
    } else if (type === "BRANCH") {
        content = <FilterBranch {...tree} depth={depth} branches={branches} />;
    } else if (type === "LEAF") {
        content = <FilterLeaf {...tree} depth={depth} />;
    }

    return <FilterTreeDiv data-depth={depth}>{content}</FilterTreeDiv>;
};

const FilterTreeDiv = styled("div")(({ theme }) => ({
    '&[data-depth="1"]:not(:last-child)': {
        borderBlockEnd: `1px solid ${theme.palette.grey[300]}`,
    },
}));

const ResetButton = styled(Button)(({ theme }) => ({
    opacity: 0.6,
    position: "relative",
    insetInlineEnd: -theme.spacing(2),
    '&[data-is-hidden="true"]': {
        display: "none",
    },
    WebkitFontSmoothing: "antialiased",
    fontSmooth: "antialiased",
}));
