import {
    useQuery,
    OperationVariables,
    DocumentNode,
    TypedDocumentNode,
    QueryHookOptions,
    QueryResult,
} from "@apollo/client";
import identity from "lodash/identity";

// hack around bad @apollo/client useQuery() types where response.data is `any` instead of `{} | undefined`
type useQueryBetterType = <
    TData = Record<string, $TSFixMe>,
    TVariables extends OperationVariables = OperationVariables,
>(
    query: DocumentNode | TypedDocumentNode<TData, TVariables>,
    options?: QueryHookOptions<TData, TVariables>
) => QueryResult<TData, TVariables>;

// this will catch if the underlying types change to become incompatible with our definition above
export default identity<useQueryBetterType>(useQuery);
