import { useMemo } from "react";

import { useNextTranslation } from "@holibob-packages/ui-core/hooks";

import { useProductFilteredListContext, useProductFilteredListFilter, useProductFilteredListOnChange } from "./hooks";

export function useProductFilteredPriceSlider() {
    const [t] = useNextTranslation("product");
    const priceLabel = t("label.price");
    const onChange = useProductFilteredListOnChange();
    const { treesResponse } = useProductFilteredListContext();
    const { priceStart, priceEnd } = useProductFilteredListFilter();

    return useMemo(() => {
        const tree = {
            fieldName: "priceTree",
            id: "PRICE",
            kind: "PRICE",
            key: "price",
            label: priceLabel,
            isRoot: false,
            type: "BRANCH",
            canReset: false,
            tree: treesResponse["priceTree"],
            selectedRange: { priceStart, priceEnd },
            onPriceChange: (range: [number, number]) => {
                const type = "FILTER_SET_KEY";
                const [priceStart, priceEnd] = range;
                const action = [
                    { type, payload: { key: "priceStart", value: priceStart } },
                    { type, payload: { key: "priceEnd", value: priceEnd } },
                ];
                onChange?.(action);
            },
        };

        return { PRICE: { ...tree, branches: [tree] } };
    }, [priceLabel, treesResponse, priceStart, priceEnd, onChange]);
}
