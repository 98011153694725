import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";

import { ErrorAlert } from "./ErrorAlert";

export class ErrorBoundary extends SentryErrorBoundary {
    static defaultProps = {
        fallback: DefaultFallback,
    };
}

function DefaultFallback({ error, resetError }: { error: Error; resetError: () => void }) {
    const message = "An error has occurred";

    return (
        <>
            <ErrorAlert style={{ marginTop: 20, marginBottom: 20 }} severity={"warning"}>
                {message}
            </ErrorAlert>
            <ErrorAlert style={{ marginTop: 20, marginBottom: 20 }}>{error.message}</ErrorAlert>
            {process.env.NODE_ENV === "development" && <button onClick={resetError}>Reset</button>}
        </>
    );
}
