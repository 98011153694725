import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/material/styles";
import { PriceSliderContainer, PriceTree } from "components/PriceSlider";
import TextInput from "components/TextInput";
import { useEffect, useRef, useState } from "react";
import { VariableSizeList } from "react-window";

import { Flex } from "@holibob-packages/ui-core/components";
import { useToggle } from "@holibob-packages/ui-core/hooks";

import { Tree } from "../hooks";
import { FilterTree } from "./ProductFilteredListFilterTree";
import { FilterTreeTitle } from "./ProductFilteredListFilterTreeTitle";

const LEAF_SIZE = 20;
const LEAF_CONTAINER_MAX_HEIGHT = 300;

type FilterBranchProps = {
    isSelected?: boolean;
    label: string;
    branches?: Tree[];
    depth: number;
    onSelect?: (id: string) => void;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "onSelect">;
export const FilterBranch = ({ isSelected, label, branches = [], depth, onSelect, ...props }: FilterBranchProps) => {
    const [isOpen, toggleIsOpen] = useToggle(isSelected);

    return (
        <Wrapper data-depth={depth} {...props}>
            <Header row spaceBetween middle onClick={toggleIsOpen}>
                <FilterTreeTitle
                    depth={depth}
                    isSelected={isSelected}
                    variant="title"
                    size={depth > 1 ? "small" : "medium"}
                >
                    {label}
                </FilterTreeTitle>
                <ExpandMoreIconStyled aria-expanded={isOpen} data-is-selected={isSelected} />
            </Header>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <FilterBranchContainer branches={branches} depth={depth} onSelect={onSelect} />
            </Collapse>
        </Wrapper>
    );
};

type FilterBranchContainerProps = Pick<FilterBranchProps, "branches" | "depth" | "onSelect">;
function FilterBranchContainer(props: FilterBranchContainerProps) {
    const { branches = [], depth, onSelect } = props;
    const priceBranch = branches.find((branch) => branch.kind === "PRICE");
    if (priceBranch) {
        if (!assertPriceTree(priceBranch)) {
            throw new Error("Price branch is not a valid price tree");
        }

        return <PriceSliderContainer {...priceBranch} />;
    }

    return <FilterBranchContent branches={branches} depth={depth} onSelect={onSelect} />;
}

function assertPriceTree(tree: unknown): tree is PriceTree {
    return typeof tree === "object" && tree !== null && "kind" in tree && tree.kind === "PRICE";
}

function FilterBranchContent({
    branches,
    depth,
    onSelect,
}: {
    branches: Tree[];
    depth: number;
    onSelect?: (id: string) => void;
}) {
    const [search, setSearch] = useState("");

    const listRef = useRef<VariableSizeList>(null);
    const rowHeights = useRef<Record<number, number>>({});

    const allBranchesAreLeafs = branches.every((branch) => branch.type === "LEAF");
    const isOverflowing = branches.length * LEAF_SIZE > LEAF_CONTAINER_MAX_HEIGHT;

    const filteredBranches = branches.filter((branch) => branch.label.toLowerCase().includes(search.toLowerCase()));

    function getRowHeight(index: number) {
        return rowHeights.current[index] + 8 || LEAF_SIZE;
    }

    // Generally we don't declare functions inside render, but in this case it's necessary to set the row height
    function Row({ index, style }: { index: number; style: React.CSSProperties }) {
        const rowRef = useRef<HTMLDivElement>(null);

        const { key, ...branch } = filteredBranches[index];

        useEffect(() => {
            if (rowRef.current) {
                const firstChild = rowRef.current.firstElementChild;
                if (!firstChild) {
                    return;
                }

                listRef.current?.resetAfterIndex(0);
                rowHeights.current = { ...rowHeights.current, [index]: firstChild.clientHeight };
            }
        }, [index, rowRef]);

        return (
            <div style={style} key={key} ref={rowRef}>
                <FilterTree depth={depth + 1} onSelect={onSelect} {...branch} />
            </div>
        );
    }

    if (allBranchesAreLeafs && isOverflowing) {
        return (
            <>
                <TextInputStyled
                    placeholder="Search"
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                    variant="standard"
                    size="small"
                    InputProps={{
                        startAdornment: <SearchIcon />,
                    }}
                />
                <VariableSizeList
                    height={LEAF_CONTAINER_MAX_HEIGHT}
                    itemCount={filteredBranches.length}
                    itemSize={getRowHeight}
                    ref={listRef}
                    width="100%"
                >
                    {Row}
                </VariableSizeList>
            </>
        );
    }
    return (
        <>
            {branches.map((branch) => (
                <FilterTree key={branch.label} depth={depth + 1} onSelect={onSelect} {...branch} />
            ))}
        </>
    );
}

const Wrapper = styled("div")(({ theme }) => ({
    '&[data-depth="1"]': {
        marginBlockStart: theme.spacing(1),
        marginBlockEnd: theme.spacing(0.5),
    },
    '&:not([data-depth="0"]):not([data-depth="1"])': {
        marginInlineStart: theme.spacing(1.5),
    },
}));

const Header = styled(Flex)({
    "&:hover": {
        cursor: "pointer",
    },
});

const ExpandMoreIconStyled = styled(ExpandMoreIcon)(({ theme }) => ({
    color: theme.palette.grey[500],
    transform: "rotate(0deg)",
    marginInlineStart: "auto",
    transition: theme.transitions.create("transform", {
        duration: "0.5s",
    }),
    '&[aria-expanded="true"]': {
        transform: "rotate(180deg)",
    },
    '&[data-isSelected="true"]': {
        fill: theme.palette.primary.main,
    },
}));

const TextInputStyled = styled(TextInput)(({ theme }) => ({
    marginInline: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
}));
