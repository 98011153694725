import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import { useSearchParams } from "next/navigation";
import { useCallback, useMemo } from "react";
import { objectToQueryParams } from "utils/objectToQueryParams";

import { useRouter, usePathname } from "@holibob-packages/ui-core/navigation";

type PaginationInitialQueryParams = {
    page?: number;
    pageSize?: number;
    sort?: { qualityMatchScore?: "asc" | "desc" };
    filter?: Record<string, unknown>;
};

type ListSearchState = Required<PaginationInitialQueryParams>;

type PaginationQueryParams = {
    page?: number;
    pageSize?: number;
    sort?: string;
    filter: string;
};

type Action = $TSFixMe;

function reducer(state: $TSFixMe, action: Action = {}) {
    const { type, payload } = action;
    switch (type) {
        case "PAGE_NEXT":
            return { ...state, page: state.page + 1 };
        case "PAGE_PREVIOUS":
            return { ...state, page: state.page - 1 };
        case "FILTER_SET_KEY": {
            const { key, value } = payload;
            return {
                ...state,
                page: 0,
                filter: { ...state.filter, [key]: value },
            };
        }
        case "FILTER_CLEAR_KEY": {
            const { key } = payload;
            const newFilter = omit(state.filter, key);
            return { ...state, filter: newFilter };
        }
        case "SORT_CHANGE": {
            const { sort } = payload;
            return { ...state, sort };
        }
        default:
            return state;
    }
}

export type UseListSearchParams = {
    initialParams?: PaginationInitialQueryParams;
    queryParamsFactory?: (searchParams: PaginationQueryParams) => URLSearchParams;
};

const STATE_DEFAULTS = {
    sort: { qualityMatchScore: "desc" },
    pageSize: 20,
    page: 0,
} as const;

export const useListSearch = (params: UseListSearchParams) => {
    const { initialParams, queryParamsFactory } = params;

    const router = useRouter();
    const pathname = usePathname();
    const searchParams = useSearchParams();

    const initialState = useMemo((): ListSearchState => {
        const params: PaginationInitialQueryParams = initialParams ?? {};
        const filter = params.filter ?? {};
        const sort = params.sort ?? STATE_DEFAULTS.sort;
        const page = params.page ?? STATE_DEFAULTS.page;
        const pageSize = params.pageSize ?? STATE_DEFAULTS.pageSize;

        return { filter, sort, page, pageSize };
    }, [initialParams]);

    const state: ListSearchState = useMemo(() => {
        const filter = searchParams.has("filter")
            ? (JSON.parse(searchParams.get("filter")!) as ListSearchState["filter"])
            : initialState.filter;
        const sort = searchParams.has("sort")
            ? (JSON.parse(searchParams.get("sort")!) as ListSearchState["sort"])
            : initialState.sort;
        const page = searchParams.has("page") ? parseInt(searchParams.get("page")!) : initialState.page;
        const pageSize = searchParams.has("pageSize") ? parseInt(searchParams.get("pageSize")!) : initialState.pageSize;

        return { filter: { ...filter, searchInDescription: true }, sort, page, pageSize };
    }, [initialState, searchParams]);

    const onChange = useCallback(
        (action?: Action) => {
            let newState = state;
            if (action?.type === "RESET") newState = initialState;
            else {
                const actions = Array.isArray(action) ? action : [action];
                actions.forEach((action) => (newState = reducer(newState, action)));
            }

            //Don't add query params if the newState matches the default param value
            const page = newState.page !== STATE_DEFAULTS.page ? newState.page : undefined;
            const pageSize = newState.pageSize !== STATE_DEFAULTS.pageSize ? newState.pageSize : undefined;
            const sort = !isEqual(newState.sort, STATE_DEFAULTS.sort) ? JSON.stringify(newState.sort) : undefined;
            const filter = JSON.stringify(newState.filter);

            const searchParams: PaginationQueryParams = { page, pageSize, sort, filter };
            const queryStringParams = queryParamsFactory
                ? queryParamsFactory(searchParams)
                : objectToQueryParams(searchParams);

            const url = `${pathname}?${queryStringParams.toString()}`;

            router.push(url);
        },
        [state, initialState, pathname, router, queryParamsFactory]
    );

    return [state, onChange] as const;
};

export default useListSearch;
