import { useState } from "react";

export const useToggle = (initialValue = false) => {
    const [on, setOn] = useState(initialValue);

    const toggleOn = () => setOn((value) => !value);

    return [on, toggleOn, setOn] as const;
};

export default useToggle;
