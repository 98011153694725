import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import { ButtonSecondary } from "components/Button";
import Text from "components/Text";
import { useNextTranslation } from "hooks/useTranslation";
import React, { useCallback } from "react";

import { makeStyles } from "@holibob-packages/ui-core/style";

const useStyles = makeStyles()({
    paginationSimpleButton: {
        width: 130,
    },
    paginationText: {
        textAlign: "center",
    },
});

export const Paganate = ({ nextPage, prevPage, onChange, pagination = {}, searchTerm }: $TSFixMe) => {
    const { classes } = useStyles();

    const prevDisabled = prevPage === undefined;
    const nextDisabled = nextPage === undefined;

    const onPageChange = useCallback(() => {
        window.scrollTo(0, 0);
    }, []);

    const onPrevious = useCallback(() => {
        onPageChange();
        onChange({ type: "PAGE_PREVIOUS" });
    }, [onChange, onPageChange]);

    const onNext = useCallback(() => {
        onPageChange();
        onChange({ type: "PAGE_NEXT" });
    }, [onPageChange, onChange]);

    const { page, pageSize, total } = pagination;
    const showPaginationDetails = page && pageSize && total;

    const renderSearchTerm = searchTerm ? `'${searchTerm}'` : "all products";
    const [t] = useNextTranslation("pagination");
    const showAllLabel = t("label.showAll");
    const productsForLabel = t("label.productsFor");
    const previousLabel = t("label.previous");
    const nextLabel = t("label.next");
    const showingLabel = t("label.showing");
    const ofLabel = t("label.of");
    const forLabel = t("label.for");

    if (prevDisabled && nextDisabled) {
        if (showPaginationDetails) {
            return (
                <Text color="primary" align="center">
                    {showAllLabel} {total} {productsForLabel} {renderSearchTerm}
                </Text>
            );
        }
        return null;
    }

    return (
        <Grid container direction="row" justifyContent="space-between" alignContent="center" spacing={2}>
            <Grid item>
                <ButtonSecondary
                    data-testid="previousButton"
                    disabled={prevDisabled}
                    onClick={onPrevious}
                    className={classes.paginationSimpleButton}
                >
                    {previousLabel}
                </ButtonSecondary>
            </Grid>
            <Hidden mdDown>
                <Grid item>
                    {showPaginationDetails && (
                        <Text data-testid="productCount" color="secondary" className={classes.paginationText}>
                            {showingLabel} {(page - 1) * pageSize + 1} -{" "}
                            {Math.min((page - 1) * pageSize + pageSize, total)} {ofLabel} {total} {forLabel}{" "}
                            {renderSearchTerm}
                        </Text>
                    )}
                </Grid>
            </Hidden>
            <Grid item>
                <ButtonSecondary
                    data-testid="nextButton"
                    disabled={nextDisabled}
                    onClick={onNext}
                    className={classes.paginationSimpleButton}
                >
                    {nextLabel}
                </ButtonSecondary>
            </Grid>
            <Hidden mdUp>
                <Grid item xs={12}>
                    {showPaginationDetails && (
                        <Text color="secondary" className={classes.paginationText}>
                            {showingLabel} {(page - 1) * pageSize + 1} -{" "}
                            {Math.min((page - 1) * pageSize + pageSize, total)} {forLabel} {renderSearchTerm}
                        </Text>
                    )}
                </Grid>
            </Hidden>
        </Grid>
    );
};

export default Paganate;
