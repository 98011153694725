import { dayjs } from "@holibob-packages/dayjs";

const isInvalidDuration = (duration: string) => {
    const momentDuration = dayjs.duration(duration).toISOString();
    return duration !== momentDuration;
};

export const durationToFormattedText = (duration: string | null, locale: string) => {
    if (!duration) return null;
    duration = duration.toUpperCase();
    if (isInvalidDuration(duration)) return null;
    const formattedText = dayjs.duration(duration).locale(locale).humanize();
    return formattedText;
};

export const durationToFormattedTextAlternative = (duration: string | null, locale: string) => {
    let formattedText = durationToFormattedText(duration, locale);
    formattedText = formattedText?.replace("a ", "1 ").replace("an ", "1 ").replace("un1 ", "1 ") ?? null;

    return formattedText;
};

export const productDurationToFormattedText = (minDuration: string, maxDuration: string, locale: string) => {
    if (!minDuration && !maxDuration) return null;
    if (minDuration === "P0D" || maxDuration === "P0D") return null;

    const minDurationFormattedText = durationToFormattedTextAlternative(minDuration, locale);
    const maxDurationFormattedText = durationToFormattedTextAlternative(maxDuration, locale);

    let formattedText: string | null = "";
    if (maxDuration === minDuration) formattedText = minDurationFormattedText;
    else if (minDuration && !maxDuration) formattedText = minDurationFormattedText;
    else if (maxDuration && !minDuration) formattedText = maxDurationFormattedText;
    else if (minDuration && maxDuration) formattedText = `${minDurationFormattedText} - ${maxDurationFormattedText}`;

    return formattedText;
};
