import React from "react";
import { Field, FieldRenderProps } from "react-final-form";

export type DecoratedFieldProps<ExtraProps = Record<string, unknown> | undefined> = { name: string } & ExtraProps;

function fieldDecorator<FieldValue, ExtraProps = Record<string, unknown> | undefined, InputValue = FieldValue>(
    Component: React.ComponentType<FieldRenderProps<FieldValue, HTMLElement, InputValue> & ExtraProps>
): React.FC<DecoratedFieldProps<ExtraProps>> {
    const DecoratedField: React.FC<DecoratedFieldProps<ExtraProps>> = (props) => {
        return <Field component={Component} {...props} />;
    };
    DecoratedField.displayName = Component.displayName ? `Decorated<${Component.displayName}>` : "DecoratedField";
    return DecoratedField;
}

export default fieldDecorator;
